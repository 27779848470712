<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-card class="mt-4">
          <v-row class="align-center">
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mt-2"
                    v-on="on"
                    text
                    small
                    @click="updateCalendar(false)"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.t("GENERAL.prev") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4" class="pa-3 pt-0">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    hide-details
                    v-model="date"
                    :label="$i18n.t('GENERAL.ACTUALDAY')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  :max="maxDate"
                  @change="look4records()"
                  :locale="$store.state.lang ? $store.state.lang : 'en'"
                  @input="menu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mt-2"
                    v-on="on"
                    fab
                    text
                    :disabled="nextDisabled"
                    small
                    @click="updateCalendar(true)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.t("GENERAL.next") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <v-combobox
                prepend-icon="mdi-account"
                :label="$i18n.t('GENERAL.user')"
                v-model="selectedUser"
                :items="users"
                @change="look4records"
                item-text="fullname"
                item-value="id"
                item-disabled="false"
              >
                <template v-slot:selection="data">
                  {{ data.item.fullname ? data.item.fullname : data.item }}
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content v-if="data.item.disabled">
                      <v-list-item-title
                        v-html="data.item.fullname"
                        class="grey--text"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="$i18n.t('GENERAL.disabledUser')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title
                        v-html="data.item.fullname"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" class="mr-5" v-on="on">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5">
                    {{ $i18n.t("SETTING.title") }}
                  </v-card-title>

                  <v-card-text>
                    <v-autocomplete
                      :label="$i18n.t('SETTING.lenguage')"
                      v-model="settings.language"
                      :items="itemsLanguage"
                    />
                    <v-autocomplete
                      v-model="settings.model"
                      :items="itemsmodel"
                      :label="$i18n.t('SETTING.model')"
                    />
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="green" text @click="dialog = false">
                      {{ $i18n.t("GENERAL.confirm") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="#0297fe"
          />
        </v-card>

        <v-card class="mt-4" elevation="0">
          <v-card v-if="transcriptions.length > 0 && !loading">
            <v-card-title v-show="transcriptions.length > 0 && !loading">
              <v-container>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="search"
                      prepend-icon="mdi-magnify"
                      :label="$i18n.t('TABLE.searchLabel')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col col></v-col>
                  <v-col cols="1" v-if="isUserAdmin() || isUserSuperAdmin()">
                    <v-btn
                      icon
                      fab
                      class="ml-2"
                      @click="deleteRows()"
                      :disabled="Boolean(!selectedRows.length)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="3" v-if="nextDisabled">
                    <v-tooltip top :color="showUpload ? 'success' : 'warning'">
                      <template v-slot:activator="{ on }">
                        <v-file-input
                          v-on="on"
                          @mouseenter.native="on.mouseenter"
                          @mouseleave.native="on.mouseleave"
                          hide-details
                          :disabled="!showUpload"
                          @change="uploadFile"
                          accept="audio/*"
                          :label="$i18n.t('ADMIN.Upload file')"
                        ></v-file-input>
                      </template>
                      <span>
                        {{
                          showUpload
                            ? $i18n.t("COMPANY.clickToUpload")
                            : $i18n.t("COMPANY.monthlyUsageReached")
                        }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="h3">
                          {{ $i18n.t("TABLE.filters") }}
                        </span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-container>
                          <v-row>
                            <span class="body-1">
                              {{ $i18n.t("TABLE.negativeSentimentRatio") }}
                            </span>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="sentimentRatioFilter"
                                type="number"
                                prepend-icon="mdi-ticket-account"
                                :label="$i18n.t('GENERAL.percentage')"
                                single-line
                                hide-details
                                suffix="%"
                              />
                            </v-col>
                            <v-col
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              <span
                                style="
                                  padding-right: 10px;
                                  display: flex;
                                  flex-direction: column;
                                "
                              >
                                <span class="body-2">
                                  {{ $i18n.t("TABLE.searchFor") }}
                                </span>
                                <span class="caption">
                                  {{ $i18n.t("TABLE.selectMinimunOne") }}
                                </span>
                              </span>
                              <v-chip-group
                                v-model="sentimentRatioTargets"
                                active-class="blue--text text--accent-4"
                                multiple
                              >
                                <v-chip value="agent">
                                  {{ $i18n.t("GENERAL.agents") }}
                                </v-chip>
                                <v-chip value="customer">
                                  {{ $i18n.t("GENERAL.customers") }}
                                </v-chip>
                              </v-chip-group>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-container>
            </v-card-title>

            <v-data-table
              @click:row="openResult"
              :headers="headers"
              :items="transcriptions"
              :search="search"
              :options.sync="options"
              :loading="loading"
              show-select
              v-model="selectedRows"
              item-key="uid"
            >
              <template #header.data-table-select></template>
              <template
                v-slot:item.data-table-select="{ item, isSelected, select }"
              >
                <v-tooltip
                  top
                  :color="
                    isSelectable(item.dateUpload.value) ? 'warning' : 'success'
                  "
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on" v-if="isUserAdmin() || isUserSuperAdmin()">
                      <v-simple-checkbox
                        :value="isSelected"
                        :readonly="isSelectable(item.dateUpload.value)"
                        :disabled="isSelectable(item.dateUpload.value)"
                        @input="select($event)"
                      >
                      </v-simple-checkbox>
                    </div>
                  </template>
                  <span>
                    {{
                      isSelectable(item.dateUpload.value)
                        ? $i18n.t("TABLE.deletedAfter90")
                        : $i18n.t("TABLE.clickToSelect")
                    }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:item.processed="{ item }">
                <label
                  class="pr-3"
                  style="display: inline-block; line-break: anywhere"
                >
                  <v-progress-circular
                    v-if="!item.processed"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-icon color="green lighten-1" v-if="item.processed"
                    >mdi-check</v-icon
                  >
                </label>
              </template>
              <template v-slot:item.callUid="{ item }">
                <label v-if="!item.callUid && item.processed">No data</label>
                <label v-else>{{ item.callUid }}</label>
              </template>
              <template v-slot:item.phonenumber="{ item }">
                <label v-if="item.phonenumber == null && item.processed">
                  {{ $i18n.t("TABLE.noData") }}
                </label>
                <label v-else>{{ item.phonenumber }}</label>
              </template>
              <template v-slot:item.origin="{ item }">
                <v-icon v-if="item.origin === 'outbound'"
                  >mdi-phone-outgoing</v-icon
                >
                <v-icon v-else-if="item.origin === 'inbound'"
                  >mdi-phone-incoming</v-icon
                >
                <label v-else>-</label>
              </template>
              <template v-slot:item.callDuration="{ item }">
                <label v-if="!item.callDuration && item.processed">
                  {{ $i18n.t("TABLE.noData") }}
                </label>
                <label v-else-if="item.processed">{{
                  showCallDuration(item.callDuration)
                }}</label>
              </template>
              <template v-slot:item.callstarttimestamp="{ item }">
                <label v-if="!item.callstarttimestamp && item.processed">
                  {{ $i18n.t("TABLE.noData") }}
                </label>
                <label v-else-if="item.processed">{{
                  showDate(new Date(item.callstarttimestamp.valueOf().value))
                }}</label>
              </template>
            </v-data-table>
          </v-card>
          <v-row
            v-else
            class="justify-center fill-height align-center"
            style="height: calc(100vh - 26vh)"
          >
            <v-col
              class="col-sm-6 col-md-4"
              v-if="!loading && !transcriptions.length && nextDisabled"
            >
              <img
                class=""
                style="width: 90%; margin: auto; display: block"
                :src="require('../../src/assets/waiting.svg')"
                alt="Image not found"
              />
            </v-col>
            <v-col
              class="col-sm-6 col-md-6"
              v-if="!loading && !transcriptions.length && nextDisabled"
            >
              <v-card
                class="pa-4 rounded"
                elevation="0"
                style="border: 2px dashed lightgray"
              >
                <p class="text-h4 text-center">
                  {{ $i18n.t("GENERAL.HI") }}
                  {{ $store.state.Auth.token.fullname }}.
                </p>
                <p v-if="this.selectedUser === 'All'">
                  {{ $i18n.t("ROOM.ALLNOFILES") }}
                </p>
                <p v-else class="text-h5 text-center">
                  {{
                    selectedUser.fullname === "You"
                      ? $i18n.t("ROOM.YOUNOFILES")
                      : selectedUser.fullname + $i18n.t("ROOM.USERNOFILES")
                  }}
                  <br />
                  <br />
                  {{
                    showUpload
                      ? $i18n.t("ROOM.DRAGDROP")
                      : $i18n.t("COMPANY.monthlyUsageReached")
                  }}
                </p>

                <v-file-input
                  filled
                  v-show="showUpload"
                  rounded
                  class="px-5"
                  @change="uploadFile"
                  accept="audio/*"
                  :label="$i18n.t('ADMIN.Upload file')"
                ></v-file-input>
              </v-card>
            </v-col>
            <v-col
              class="col-sm-6 col-md-4"
              v-if="!loading && !transcriptions.length && !nextDisabled"
            >
              <img
                class=""
                style="width: 90%; margin: auto; display: block"
                :src="require('../../src/assets/waiting.svg')"
                alt="Image not found"
              />
            </v-col>
            <v-col
              class="ol-sm-6 col-md-6"
              v-if="!loading && !transcriptions.length && !nextDisabled"
            >
              <v-card class="p-4 rounded" elevation="0">
                <p class="text-h4 text-center">
                  {{ $i18n.t("GENERAL.HI") }}
                  {{ $store.state.Auth.token.fullname }}.
                </p>
                <p
                  v-if="this.selectedUser === 'All'"
                  class="text-h5 text-center"
                >
                  No files have been uploaded on this day.
                </p>
                <p v-else class="text-h5 text-center">
                  {{
                    selectedUser.fullname === "You"
                      ? $i18n.t("ROOM.YOUNOFILESDAY")
                      : selectedUser.fullname + $i18n.t("ROOM.USERNOFILESDAY")
                  }}
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-dialog v-model="uploadDialog" width="500">
        <v-card>
          <v-card-title class="text-h5">
            {{ $i18n.t("SETTING.uploadInformation") }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              :label="$i18n.t('SETTING.callIdLabel')"
              v-model="settings.callUid"
              :rules="requiredInput"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              :label="$i18n.t('USER.phoneLabel')"
              type="tel"
              v-model="settings.phonenumber"
              :rules="requiredInput"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              :label="$i18n.t('SETTING.callTimeLabel')"
              type="datetime-local"
              v-model="settings.callstarttimestamp"
              :rules="requiredInput"
              hide-details="auto"
            ></v-text-field>
            <v-checkbox
              :label="$i18n.t('SETTING.originLabel')"
              v-model="settings.originEnabled"
            ></v-checkbox>
            <v-radio-group
              v-show="settings.originEnabled"
              :inline="true"
              :disabled="!settings.originEnabled"
              v-model="settings.origin"
            >
              <v-radio
                :label="$i18n.t('SETTING.inboundLabel')"
                value="inbound"
              ></v-radio>
              <v-radio
                :label="$i18n.t('SETTING.outboundLabel')"
                value="outbound"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              text
              @click="sendData"
              :disabled="
                !settings.callstarttimestamp ||
                !settings.phonenumber ||
                !settings.callUid
              "
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col cols="12" v-show="false">
        <RecordingPanel
          @reload="getTranscriptions()"
          @add_doc="add_doc"
          @uploadTranscript="uploadTranscript"
          :file="file"
          v-for="(file, index) in files"
          v-show="
            search == '' || file.name.indexOf(search) != -1 ? true : false
          "
          :index="index"
          :key="index + file.name"
          :settings="settings"
        >
        </RecordingPanel>
      </v-col>
    </v-row>

    <VueFullScreenFileDrop
      @drop="onDrop"
      text="Upload File"
      v-if="showUpload"
    />
  </v-container>
</template>

<script>
import Transcription from "./../models/transcription.js";
import User from "./../models/user.js";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import RecordingPanel from "./RecordingPanel.vue";
import useDeleteCalls from "../hooks/useDeleteCalls";
import { debounce } from "lodash";
import { useValidateUsageLimit } from "@/hooks/useValidateUsageLimit";
const moment = require("moment-timezone");

export default {
  name: "UploadView",
  components: { VueFullScreenFileDrop, RecordingPanel },
  async mounted() {
    await this.getUsers(
      this.$store.state.Auth.token.claims.user_id.split("@")[1]
    );
    await this.look4records();
    await this.validateUsageLimit();
  },
  methods: {
    async look4records() {
      this.loading = true;
      this.$store.commit("setSelectedUser", this.selectedUser);
      const company = this.$store.state.Auth.token.claims.user_id.split("@")[1];
      if (this.selectedUser === "All") {
        this.$store.commit(
          "setSelectedUserID",
          this.$store.state.Auth.token.claims.user_id
        );
      } else
        this.$store.commit(
          "setSelectedUserID",
          this.selectedUser.id + "@" + company
        );
      await this.getDayCount();
      await this.getTranscriptions();
      this.loading = false;
    },
    async getUsers(companyId) {
      try {
        this.companySelected = companyId;
        const users = await User.getAll(companyId);
        const userId =
          this.$store.state.Auth.token.claims.user_id.split("@")[0];
        const user = users.filter((elem) => elem.id === userId)[0];
        user.fullname = "You";

        if (this.isUserAdmin() || this.isUserSuperAdmin()) {
          this.users = ["All", ...users];
          this.selectedUser = this.$store.state.selectedUser || "All";
        } else {
          this.users = [user];
          this.selectedUser = this.$store.state.selectedUser || user;
        }
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: error.message,
        });
      }
    },
    async getDayCount() {
      const selectedUserID =
        this.$store.state.selectedUser === "All"
          ? "All"
          : this.$store.state.selectedUser.id;
      const selectedDate = moment.tz(this.date, moment.tz.guess());
      const selectedDateUtc = selectedDate.clone().utc();

      const count = await Transcription.getDayCount(
        selectedDateUtc.format(),
        selectedUserID
      );
      if (count) {
        this.totalRecordings = count;
      }
    },
    async getTranscriptions() {
      const { page } = this.options;
      const dateCheck = moment(this.date, "YYYY-MM-DD");
      const now = moment();
      if (now.diff(dateCheck, "days") > 0) {
        this.nextDisabled = false;
      } else {
        this.nextDisabled = true;
      }
      const selectedUserID =
        this.$store.state.selectedUser === "All"
          ? "All"
          : this.$store.state.selectedUser.id;
      const selectedDate = moment.tz(this.date, moment.tz.guess());
      const selectedDateUtc = selectedDate.clone().utc();

      const filters = {
        sentimentRatio: {
          percentage: Number(this.sentimentRatioFilter),
          targets: this.sentimentRatioTargets,
        },
      };

      const dayTranscriptions = await Transcription.getTranscriptionsInADay(
        selectedDateUtc.format(),
        selectedUserID,
        page,
        -1, // Bring all records
        filters
      );
      this.transcriptions = dayTranscriptions;
    },
    async updateCalendar(bool) {
      if (bool) {
        this.date = moment(this.date, "YYYY-MM-DD")
          .utc()
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        this.date = moment(this.date, "YYYY-MM-DD")
          .utc()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }

      this.options.page = 1;

      await this.look4records();
    },
    async getSecondsFromFile(file) {
      if (file) {
        const context = new AudioContext();
        const buffer = await file.arrayBuffer();
        const audioBuffer = await context.decodeAudioData(
          buffer,
          async (audioBuffer) => {
            return audioBuffer;
          }
        );
        return audioBuffer.duration || 0;
      }
      return 0;
    },
    validateFileType(file) {
      return (
        file.name.toLowerCase().indexOf(".wav") !== -1 ||
        file.name.toLowerCase().indexOf(".opus") !== -1 ||
        file.name.toLowerCase().indexOf(".mp3") !== -1 ||
        file.name.toLowerCase().indexOf(".flac") !== -1 ||
        file.name.toLowerCase().indexOf(".webm") !== -1 ||
        file.name.toLowerCase().indexOf(".ogg") !== -1
      );
    },
    async onDrop(formData, files) {
      let totalSeconds = 0;
      let validFile = true;
      for (const file of files) {
        // check file types
        validFile = validFile && this.validateFileType(file);
        const fileDuration = validFile
          ? await this.getSecondsFromFile(file)
          : 0;
        totalSeconds += fileDuration;
      }

      if (validFile) {
        const shouldUpload = await this.validateUsageLimit(totalSeconds);
        if (shouldUpload) {
          this.formInfo.files = files;
          this.settings.callUid = null;
          this.settings.callstarttimestamp = null;
          this.settings.phonenumber = null;
          this.settings.originEnabled = false;
          this.settings.origin = null;
          this.uploadDialog = true;
        }
      } else {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          text: this.$i18n.t("GENERAL.fileNotSupported"),
        });
      }
    },
    sendData() {
      this.uploadDialog = false;
      const files = this.formInfo.files;
      for (const file of files) {
        file.ready = false;
        file.progress = 0;
        file.result = false;

        this.files.push(file);

        this.fileResultList.push({
          ready: false,
          filename: file.name,
          summarization: "Processing...",
          result: null,
        });
      }
    },
    uploadTranscript(transcript) {
      this.transcriptions.unshift(transcript);
    },
    async openResult(result) {
      if (result.processed) {
        if (result?.summarization === "No speech detected") {
          return false;
        }

        const selectedDate = moment.tz(this.date, moment.tz.guess());
        const selectedDateUtc = selectedDate.clone().utc();

        this.$router.push(
          `/assessment/${selectedDateUtc.format("YYYYMMDD")}/${result.userid}/${
            result.uid
          }`
        );
      }
    },
    async uploadFile(file) {
      this.onDrop("newFile", [file]);
    },
    add_doc() {
      this.totalRecordings = this.totalRecordings + 1;
    },
    showDate(date) {
      return this.$store.state.time === "24"
        ? moment(date).format("MM/DD/YYYY HH:mm")
        : moment(date).format("MM/DD/YYYY hh:mm A");
    },
    showCallDuration(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);

      let duration = "";

      if (hrs > 0) {
        duration += "" + hrs + ":" + (mins < 10 ? "0" : "");
      }

      duration += "" + mins + ":" + (secs < 10 ? "0" : "");
      duration += "" + secs;

      return duration;
    },
    async deleteRows() {
      const deleteCall = useDeleteCalls();
      const callsData = this.selectedRows.map((call) => {
        const { uid, company, userid, dateUpload, filename } = call;
        return {
          uid,
          company,
          userId: userid,
          date: dateUpload.value,
          filename,
        };
      });
      await deleteCall(callsData, this.$router);
    },
    isUserAdmin() {
      return this.$store.state.Auth.token.role === "admin";
    },
    isUserSuperAdmin() {
      return this.$store.state.Auth.token.role === "superuser";
    },
    isSelectable(dateUploaded) {
      const now = moment().toDate();
      const deletableTime = moment(dateUploaded).add(90, "m").toDate();
      return !(now >= deletableTime);
    },
    isNumber(value) {
      return typeof value === "number" && isFinite(value);
    },
    async validateUsageLimit(fileDuration = null) {
      try {
        const {
          company: { secondsLimitPerCompany },
          monthlySeconds,
          limitReached,
        } = await useValidateUsageLimit();

        if (fileDuration) {
          if (secondsLimitPerCompany === null) return true;

          const newBilledDuration =
            Number(monthlySeconds) + Number(fileDuration);

          if (
            this.isNumber(secondsLimitPerCompany) &&
            this.isNumber(monthlySeconds) &&
            secondsLimitPerCompany < newBilledDuration
          ) {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "GET",
              text: this.$i18n.t("COMPANY.uploadExceedLimit"),
            });
            return false;
          }
          return true;
        }

        if (limitReached) this.showUpload = false;
      } catch (e) {
        console.error(e);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error while trying to validate monthly seconds billed",
        });
      }
    },
  },
  data: () => ({
    selectedUser: null,
    users: [],
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    totalRecordings: 0,
    maxDate: moment().format("YYYY-MM-DD"),
    nextDisabled: true,
    loading: true,
    date: moment().format("YYYY-MM-DD"),
    menu: false,
    transcriptions: [],
    files: [],
    dialog: false,
    uploadDialog: false,
    formInfo: {
      files: [],
    },
    file: null,
    enabled: false,
    settings: {
      pipeline: "n2p",
      WHISPER: "true",
      intent: [],
      topic: [],
      mode: "mono",
      model: "general",
      API: "Current",
      AUDITOK: "FALSE",
      language: "en",
      callUid: null,
      callstarttimestamp: null,
      phonenumber: null,
      originEnabled: false,
      origin: null,
    },
    itemsLanguage: ["en", "es", "es-419", "pt", "automatic"],
    itemsmodel: ["general", "meeting", "phonecall", "finance"],
    search: "",
    fileResultList: [],
    selectedRows: [],
    requiredInput: [(value) => !!value || "Required."],
    sentimentRatioFilter: "",
    sentimentRatioTargets: [],
    showUpload: true,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("TABLE.headers.status"),
          value: "processed",
        },
        {
          text: this.$i18n.t("TABLE.headers.filename"),
          value: "filename",
          filterable: true,
        },
        {
          text: this.$i18n.t("TABLE.headers.callId"),
          value: "callUid",
          filterable: true,
        },
        {
          text: this.$i18n.t("TABLE.headers.summarization"),
          value: "summarization",
          filterable: true,
        },
        {
          text: this.$i18n.t("TABLE.headers.agentId"),
          value: "userid",
          filterable: true,
        },
        {
          text: this.$i18n.t("TABLE.headers.phoneNumber"),
          value: "phonenumber",
          filterable: true,
        },
        {
          text: this.$i18n.t("TABLE.headers.callTime"),
          value: "callstarttimestamp",
        },
        {
          text: this.$i18n.t("TABLE.headers.callDuration"),
          value: "callDuration",
        },
        {
          text: this.$i18n.t("TABLE.headers.origin"),
          value: "origin",
        },
      ];
    },
  },
  watch: {
    intentmodel(val) {
      if (val.length > 10) {
        this.$nextTick(() => this.intentmodel.pop());
      }
    },
    options: {
      handler() {
        if (
          this.transcriptions.length > 0 &&
          this.transcriptions.length < 2 &&
          !this.transcriptions[0].processed
        ) {
          return false;
        }
        this.getTranscriptions();
      },
      deep: true,
    },
    sentimentRatioFilter: debounce(function () {
      if (!this.sentimentRatioTargets.length) return false;
      this.look4records();
    }, 500),
    sentimentRatioTargets: {
      handler() {
        if (!this.sentimentRatioFilter.length) return false;
        this.look4records();
      },
    },
  },
};
</script>
